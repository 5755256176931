<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('Queue-list') }}
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-autocomplete
            v-model="customer_id_pri"
            :items="customerList"
            :label="$t('choose_a_checker')"
            :placeholder="$t('input_three_charector')"
            item-text="customer_fullname"
            outlined
            :filter="customFilter"
            dense
            :search-input.sync="searchTextCustomer"
            hide-details
            :no-data-text="statusText"
            :loading="searchCustomerLoading"
            auto-select-first
            item-value="customer_id_pri"
          >
            <template v-slot:item="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
            <template v-slot:selection="{ item }">
              {{ item.customer_fullname }}
              ({{ item.customer_id }})
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-dialog
            ref="dateEnd"
            v-model.trim="isShowDate"
            :return-value.sync="opd_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="opd_date"
                :label="$t('to')"
                readonly
                hide-details
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-tooltip
                    color="#212121"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="opd_date = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t("all_dates") }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="opd_date"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.dateEnd.save(opd_date)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="secondary"
                @click="isShowDate = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.checkq_type_id`]="{ item }">
          <v-chip
            small
            :color="item.checkq_type_id == 1? 'info' : 'error'"
            :class="item.checkq_type_id == 1? `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text` : `v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
          >
            {{ item.checkq_type_id == 1 ? $t("check") : $t("service") }}
          </v-chip>
        </template>
        <template v-slot:[`item.checkq_id`]="{ item }">
          <span
            class="primary--text"
            style="cursor: pointer"
          >
            {{ item.opd_id ? item.opd_id : "" }}
            {{ item.serve_id ? item.serve_id : "" }}
          </span>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <ConversDateTime :datetime="item.date" /> {{ item.time }}
        </template>
        <template v-slot:[`item.opd_status_id`]="{ item }">
          <div v-if="item.checkq_type_id == 1">
            <span v-if="item.opd_status_id == 0">
              <v-chip
                small
                color="error"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
              >

                {{ $t("cancel") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 1">
              <v-chip
                small
                color="warning"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
              >

                {{ $t("wait") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 2">
              <v-chip
                small
                color="primary"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
              >

                {{ $t("examination_room") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 3">
              <v-chip
                small
                color="warning"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
              >

                {{ $t("wait_treatment") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 4">
              <v-chip
                small
                color="primary"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
              >
                {{ $t("receive_service") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 5">
              <v-chip
                small
                color="secondary"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg secondary--text`"
              >

                {{ $t("pay_for") }}</v-chip>
            </span>
            <span v-if="item.opd_status_id == 6">
              <v-chip
                small
                color="success"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
              >

                {{ $t("success") }}</v-chip>
            </span>
          </div>
          <div v-if="item.checkq_type_id == 2">
            <span v-if="item.serve_status_id == 0">
              <v-chip
                small
                color="error"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg error--text`"
              >
                {{ $t("cancel") }}</v-chip>
            </span>
            <span v-if="item.serve_status_id == 1">
              <v-chip
                small
                color="warning"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`"
              >

                {{ $t("waiting_service") }}</v-chip>
            </span>
            <span v-if="item.serve_status_id == 2">
              <v-chip
                small
                color="primary"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
              >

                {{ $t("use_service") }}</v-chip>
            </span>
            <span v-if="item.serve_status_id == 3">
              <v-chip
                small
                color="success"
                :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
              >

                {{ $t("success") }}</v-chip>
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendarBlank } from '@mdi/js'
import useQueueList from './useQueueList'
import ConversDateTime from '@/components/basicComponents/ConversDateTime.vue'

export default {
  components: {
    ConversDateTime,
  },
  setup() {
    const {
      customerList,
      customer_id_pri,
      searchTextCustomer,
      statusText,
      searchCustomerLoading,

      dataTableList,
      totalDataTableList,
      segmentId,
      options,
      loading,
      footer,
      columns,

      isShowDate,
      opd_date,

      customFilter,
      getCustomerList,
    } = useQueueList()

    return {
      customerList,
      customer_id_pri,
      searchTextCustomer,
      statusText,
      searchCustomerLoading,

      dataTableList,
      totalDataTableList,
      segmentId,
      options,
      loading,
      footer,
      columns,

      isShowDate,
      opd_date,

      customFilter,
      getCustomerList,

      icons: {
        mdiCalendarBlank,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
