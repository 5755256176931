import { ref, watch } from '@vue/composition-api'
import customerSelection from '@/api/customerSelection'
import queue from '@/api/queue/queue'
import shop from '@/api/shop'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'

export default function useQueueList() {
  const customerList = ref([])
  const customer_id_pri = ref('')
  const searchTextCustomer = ref('')
  const statusText = ref('')
  const searchCustomerLoading = ref(false)
  const shop_id_pri = ref('')

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'id',
      width: 30,
    },
    {
      text: i18n.t('a_number'),
      value: 'checkq_id',
      align: 'center',
      width: 90,
    },
    {
      text: i18n.t('type'),
      value: 'checkq_type_id',
      align: 'center',
      width: 100,
    },
    {
      text: i18n.t('date'),
      value: 'date',
      align: 'center',
      width: 130,
    },
    {
      text: i18n.t('select_customers'),
      value: 'customer_fullname',
      width: 230,
    },
    {
      text: i18n.t('doctor_inspector'),
      value: 'user_fullname',
      width: 230,
    },
    {
      text: i18n.t('service_room'),
      value: 'room_name',
      width: 180,
    },
    {
      text: i18n.t('status'),
      value: 'opd_status_id',
      align: 'center',
      width: 100,
    },

    // {
    //   text: i18n.t('check'),
    //   value: 'opd_check',
    //   align: 'center',
    //   width: 100,
    // },
  ])

  const moment = require('moment')

  const isShowDate = ref(false)
  const opd_date = ref(moment(new Date()).format('YYYY-MM-DD'))

  const isCancel = ref(false)
  const idCancel = ref('')

  const { customerSelectionList } = customerSelection
  const { queueList } = queue
  const { shop: shopGet } = shop

  shopGet().then(res => {
    shop_id_pri.value = res.shop_id_pri
  })

  const customFilter = (item, queryText) => {
    const textOne = item.customer_fullname.toLowerCase()
    const textThree = item.customer_id.toLowerCase()
    const textFour = item.customer_tel.toLowerCase()
    const searchText = queryText.toLowerCase()

    return (
      textOne.indexOf(searchText) > -1
      || textThree.indexOf(searchText) > -1
      || textFour.indexOf(searchText) > -1
    )
  }

  const fetchDataTable = () => {
    loading.value = true
    queueList({
      opd_date: opd_date.value,
      shop_id_pri: shop_id_pri.value,
      customer_id_pri: customer_id_pri.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const {
        data, count, segment,
      } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  const getCustomerList = () => {
    customerSelectionList({
      searchtext: searchTextCustomer.value,
    }).then(res => {
      customerList.value = res
      searchCustomerLoading.value = false
      statusText.value = !res.length ? i18n.t('no_data') : i18n.t('input_three_charector')
    })
  }

  watch(searchTextCustomer, value => {
    if (value && value.length >= 3) {
      statusText.value = i18n.t('data_loading')
      searchCustomerLoading.value = true
      getCustomerList()
    } else {
      customerList.value = []
      statusText.value = i18n.t('input_three_charector')
    }
  })

  watch([customer_id_pri, opd_date, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    customerList,
    customer_id_pri,
    searchTextCustomer,
    statusText,
    searchCustomerLoading,
    isCancel,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    idCancel,
    isShowDate,
    opd_date,

    customFilter,
    getCustomerList,
    fetchDataTable,
  }
}
